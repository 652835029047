import React, { useEffect, useMemo, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
//components
import BannerCard from './../components/Home/BannerCard';

//images
import baner1 from './../assets/images/home-banner/img1.png';
import baner2 from './../assets/images/home-banner/img2.png';
import Shape2 from './../assets/images/home-banner/shape2.png';
import Shape4 from './../assets/images/home-banner/shape4.png';

import about8 from './../assets/images/about/about-8.png';
import about7 from './../assets/images/about/about-7.png';
import about6 from './../assets/images/about/about-6.png';
import about5 from './../assets/images/about/about-5.png';
import about2 from './../assets/images/about/about-2.png';
import about3 from './../assets/images/about/about-3.png';
import about4 from './../assets/images/about/about-4.png';
import about1 from './../assets/images/about/about-1.png';

import { useTranslation } from 'react-i18next';
import PriceBlog from '../components/About/PriceBlog';
import { sendData } from '../api/axiosApi';

const ImageBox = ({image, changeClass}) =>{
    return(
        
        <div className="col-6">
            <div className={`image-box ${changeClass}`}>
                <img src={image} alt="" />
            </div>
        </div>
        
    )
}
  
function Home(){
	const { t } = useTranslation();
	const navigation = useNavigate();
    const [data, setData] = useState();
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const recaptchaRef = React.createRef();
    const submitHandler = (e) => {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();

        if(recaptchaValue.length == 0) 
        { 
            //reCaptcha not verified
            setRecaptchaError(true);
            e.preventDefault();
            return false;
        }
        sendData(
            recaptchaValue, 
            data.dzFirstName,
            data.dzLastName,
            data.dzEmail,
            data.dzPhoneNumber,
            data.dzMessage
        ).then((response) => {
            const status = response.data;
            recaptchaRef.current.reset();

            if (status === 'OK') {
                setData({
                    dzFirstName: '',
                    dzLastName: '',
                    dzEmail: '',
                    dzPhoneNumber: '',
                    dzMessage: '',
                })
                setSuccessMessage(true);
                setTimeout(() => {setSuccessMessage(false)}, 5000)
            }
        });
    };
    const onHandleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    function onChange(value) {
        setRecaptchaError(false);
    }

    const fields = useMemo(() => {
        return (
            <>
                <div className="col-xl-6 mb-3 mb-md-4">
                    <input value={data?.dzFirstName} onChange={onHandleChange} required name="dzFirstName" type="text" className="form-control" placeholder={t('contact.first-name')} />
                </div>
                <div className="col-xl-6 mb-3 mb-md-4">
                    <input value={data?.dzLastName} onChange={onHandleChange} required name="dzLastName" type="text" className="form-control" placeholder={t('contact.last-name')} />
                </div>
                <div className="col-xl-6 mb-3 mb-md-4">
                    <input value={data?.dzEmail} onChange={onHandleChange} required name="dzEmail"  type="email" className="form-control" placeholder={t('contact.email-address')} />
                </div>
                <div className="col-xl-6 mb-3 mb-md-4">
                    <input value={data?.dzPhoneNumber} onChange={onHandleChange} required name="dzPhoneNumber"  type="text" className="form-control" placeholder={t('contact.phone-no')} />
                </div>
                <div className="col-xl-12 mb-3 mb-md-4">
                    <textarea value={data?.dzMessage} onChange={onHandleChange} required name="dzMessage"  className="form-control" placeholder={t('contact.message')}></textarea>
                </div>
            </>
        )
    }, [data]);

	return(
		<>
			<div className="page-content">
				<div className="main-bnr style-1" id='home'>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 text-center">
								<h1 className="" >{t('home.title')}</h1>
								<p className="text text-primary " >{t('home.title-description')}</p>
								<a href={"#about-us"} className="btn space-lg btn-gradient btn-shadow btn-primary " >{t('home.get-started')}</a>
								<ul className="image-before">
									<li className="left-img"><img src={baner1} alt="" /></li>
									<li className="right-img"><img src={baner2} alt="" /></li>
								</ul>
							</div>
						</div>
					</div>
					<img className="bg-shape1" src={Shape2} alt="" />
					<img className="bg-shape2" src={Shape2} alt="" />
					<img className="bg-shape3" src={Shape4} alt="" />
					<img className="bg-shape4" src={Shape4} alt="" />
				</div>
				<div className="clearfix bg-primary-light">
					<div className="container">
						<div className="currancy-wrapper">
							<div className="row justify-content-center">
								<BannerCard />
							</div>
						</div>
					</div>
				</div>
				<section className="content-inner about-sec bg-primary-light" id='about-us'>
                    <div className="container">
                        <div className="row about-bx2 style-1 align-items-center">
                            <div className="col-lg-6">
                                <div className="dz-media">
                                    <div className="row align-items-end">                                        
                                        <ImageBox image={about4} changeClass="image-box-1" />
                                        <ImageBox image={about8} changeClass="image-box-2" />                                      
                                    </div>
                                    <div className="row">                                        
                                        <ImageBox image={about3} changeClass="image-box-3" />
                                        <ImageBox image={about2} changeClass="image-box-4" />                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 about-content ps-lg-5 m-b30">
                                <div className="section-head">
                                    <h2 className="title">{t('aboutUs.creating-successful-trading.title')}</h2>
                                    <p className="m-0 lh-base">{t('aboutUs.creating-successful-trading.description')}</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <img className="bg-shape1" src={Shape2} alt="" />
					<img className="bg-shape2" src={Shape4} alt="" />
					<img className="bg-shape3" src={Shape4} alt="" />
					<img className="bg-shape4" src={Shape4} alt="" />
                </section>
                <section className="content-inner p-0 bg-primary-light video-bx-wrapper">
                    <img className="bg-shape1" src={Shape2} alt="" />
                    <div className="container">
                        <div className="row about-bx2 style-1 align-items-center">
                            <div className="col-lg-6 about-content ps-lg-5 m-b30">
                                <div className="section-head">
                                    <h2 className="title">{t('aboutUs.our-indicators.title')}</h2>
                                    <p className="m-0 lh-base">{t('aboutUs.our-indicators.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="dz-media">
                                    <div className="row align-items-end">                                        
                                        <ImageBox image={about5} changeClass="image-box-1" />
                                        <ImageBox image={about6} changeClass="image-box-2" />                                      
                                    </div>
                                    <div className="row">                                        
                                        <ImageBox image={about7} changeClass="image-box-3" />
                                        <ImageBox image={about1} changeClass="image-box-4" />                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>      
                </section>
                <section className="content-inner bg-light pricing-plan-wrapper2" id='pricing'>
                    <img className="bg-shape2" src={Shape2} alt="" />
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title">{t('aboutUs.subscription-prices')}</h2>
                        </div>
                        <div className="row justify-content-center">
                            <PriceBlog />
                        </div>
                    </div>    
                </section>
				<section className="content-inner contact-form-wraper style-1" id='contact'>
			        <div className="container">
                        <div className="row align-items-center justify-content-center">
                            {/* <div className="col-xl-5 col-lg-5 m-b30">
                                <div className="info-box">
                                    <div className="info">
                                        <h2>{t('contact.title')}</h2>
                                        <p className="font-18">{t('contact.description')}</p>
                                    </div>
                                    
                                    <div className="widget widget_about">
                                        <div className="widget widget_getintuch">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-phone"></i>
                                                    <span style={{color: '#7E87BF'}}>{t('contact.number-1')}<br/>{t('contact.number-2')}</span> 
                                                </li>
                                                <li>
                                                    <i className="fa fa-envelope"></i> 
                                                    <span style={{color: '#7E87BF'}}>{t('contact.email-1')}<br/>{t('contact.email-2')}</span>
                                                </li>
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    <span style={{color: '#7E87BF'}}>{t('contact.address-1')}<br/>{t('contact.address-2')}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="social-box dz-social-icon style-3">
                                        <h6>{t('contact.our-socials')}</h6>
                                        <ul className="social-icon">
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://www.facebook.com/"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://www.instagram.com/"><i className="fa-brands fa-instagram"></i></a></li>
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://twitter.com/"><i className="fa-brands fa-twitter"></i></a></li>
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://youtube.com/"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                                
                            <div className="col-xl-7 col-lg-7">
                                <div className="contact-box">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h2 className="mb-0">{t('contact.get-in-touch')}</h2>
                                                <p className="mb-0 font-18 text-primary">{t('contact.form-description')}</p>
                                            </div>
                                            <form className="dzForm" id="demo-form" onSubmit={(e) => submitHandler(e)}>
                                                <div className="dzFormMsg"></div>
                                                <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                                
                                                <div className="row">
                                                    {fields}
                                                    <div className="captcha mb-2" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                                            onChange={onChange}
                                                            size={'normal'}
                                                        />
                                                        {recaptchaError && (<div className='recaptcha-error'>
                                                                {t('recaptcha-error')}
                                                            </div>
                                                        )}
                                                        {successMessage && (<div className='success-send-message'>
                                                                {t('success-send-message')}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className="col-xl-12 mb-3 mb-md-4">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" {style="display:none;" data-recaptcha="true"  data-error="Please complete the Captcha" />
                                                    </div> */}
                                                    {/* <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"></div> */}
                                                    <div className="col-xl-12">
                                                        <button className="btn btn-outline-light"
                                                            type='submit'>{t('contact.submit-now')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
			</div>		
		</>
	)
} 
export default Home;